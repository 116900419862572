import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

export default function ReRoute() {
  useEffect(() => {
    navigate('/');
  });
  return (
    <>
      <h1>Redirecting...</h1>
    </>
  );
}
